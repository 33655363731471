<template>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
      <div class="flex flex-col mb-16 sm:text-center">
        <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {{ $t('faq.header') }}
          </h2>
        </div>
      </div>
      <div class="space-y-4">
        <div class="border rounded shadow-sm">
          <button type="button" class="flex items-center justify-between w-full p-4 focus:outline-none" @click="toggle_answer_1">
            <p class="text-lg font-medium">{{ $t('faq.question_1') }}</p>
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200 transform rotate-180"
                   v-if="answer_1">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200" v-if="!answer_1">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
            </div>
          </button>
          <div class="p-4 pt-0" v-if="answer_1">
            <p class="text-gray-700">{{ $t('faq.answer_1') }}</p>
          </div>
        </div>
        <div class="border rounded shadow-sm">
          <button type="button" class="flex items-center justify-between w-full p-4 focus:outline-none" @click="toggle_answer_2">
            <p class="text-lg font-medium">{{ $t('faq.question_2') }}</p>
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200 transform rotate-180"
                   v-if="answer_2">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200" v-if="!answer_2">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
            </div>
          </button>
          <div class="p-4 pt-0" v-if="answer_2">
            <p class="text-gray-700">{{ $t('faq.answer_2') }}</p>
          </div>
        </div>
        <div class="border rounded shadow-sm">
          <button type="button" class="flex items-center justify-between w-full p-4 focus:outline-none" @click="toggle_answer_3">
            <p class="text-lg font-medium">{{ $t('faq.question_3') }}</p>
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200 transform rotate-180"
                   v-if="answer_3">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200" v-if="!answer_3">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
            </div>
          </button>
          <div class="p-4 pt-0" v-if="answer_3">
            <p class="text-gray-700">{{ $t('faq.answer_3') }}</p>
          </div>
        </div>
        <div class="border rounded shadow-sm">
          <button type="button" class="flex items-center justify-between w-full p-4 focus:outline-none" @click="toggle_answer_4">
            <p class="text-lg font-medium">{{ $t('faq.question_4') }}</p>
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200 transform rotate-180"
                   v-if="answer_4">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200" v-if="!answer_4">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
            </div>
          </button>
          <div class="p-4 pt-0" v-if="answer_4">
            <p class="text-gray-700">{{ $t('faq.answer_4') }}</p>
          </div>
        </div>
        <div class="border rounded shadow-sm">
          <button type="button" class="flex items-center justify-between w-full p-4 focus:outline-none" @click="toggle_answer_5">
            <p class="text-lg font-medium">{{ $t('faq.question_5') }}</p>
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200 transform rotate-180"
                   v-if="answer_5">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
              <svg viewBox="0 0 24 24" class="w-3 text-gray-600 transition-transform duration-200" v-if="!answer_5">
                <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                          points="2,7 12,17 22,7" stroke-linejoin="round">
                </polyline>
              </svg>
            </div>
          </button>
          <div class="p-4 pt-0" v-if="answer_5">
            <p class="text-gray-700">{{ $t('faq.answer_5') }}</p>
            <table class="text-left m-4">
              <tbody>
                <tr>
                  <td class="py-4 px-6 border-b border-grey-light">{{ $t('order.TALLINN') }}</td>
                  <td class="py-4 px-6 border-b border-grey-light text-center">7€</td>
                </tr>
                <tr>
                  <td class="py-4 px-6 border-b border-grey-light">{{ $t('order.TALLINN_NEARBY') }}</td>
                  <td class="py-4 px-6 border-b border-grey-light text-center">11€</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  props: {},
  data () {
    return {
      answer_1: false,
      answer_2: false,
      answer_3: false,
      answer_4: false,
      answer_5: false,
    }
  },
  methods: {
    toggle_answer_1 () {
      this.answer_1 = !this.answer_1
    },
    toggle_answer_2 () {
      this.answer_2 = !this.answer_2
    },
    toggle_answer_3 () {
      this.answer_3 = !this.answer_3
    },
    toggle_answer_4 () {
      this.answer_4 = !this.answer_4
    },
    toggle_answer_5 () {
      this.answer_5 = !this.answer_5
    }
  }
}
</script>

