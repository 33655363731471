import Router from 'vue-router'
import Bouquets from '@/views/Bouquets.vue'
import Boxes from '@/views/Boxes.vue'
import Card from '@/views/Card.vue'
import Contact from '@/views/Contact.vue'
import Home from '@/views/Home.vue'
import Faq from '@/views/Faq.vue'
import Order from '@/views/Order.vue'
import Policy from '@/views/Policy.vue'
import Terms from '@/views/Terms.vue'
import Vue from 'vue'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	hash: false,

	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/bouquets',
			name: 'bouquets',
			component: Bouquets
		},
		{
			path: '/boxes',
			name: 'boxes',
			component: Boxes
		},
		{
			path: '/card/:code/',
			name: 'card',
			component: Card
		},
		{
			path: '/contact',
			name: 'contact',
			component: Contact
		},
		{
			path: '/faq',
			name: 'faq',
			component: Faq
		},
		{
			path: '/order/:code/',
			name: 'order',
			component: Order
		},
		{
			path: '/policy',
			name: 'policy',
			component: Policy
		},
		{
			path: '/terms',
			name: 'terms',
			component: Terms
		}
	]
})

export default router
