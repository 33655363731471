<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap -m-4">
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full" v-for="(row) in products" :key="row.code" @click="open_card(row.code)">
          <a class="block h-120 rounded overflow-hidden">
            <img
                v-if=has_picture(row)
                class="object-cover object-center w-full h-full block"
                :src="get_picture(row.pictures[0])">
          </a>
          <div class="mt-4">
            <h2 class="text-gray-900 title-font text-lg font-medium">{{ get_name(row) }}</h2>
            <p class="mt-1">€ {{ parseFloat(row.price).toFixed(2) }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api'

export default {
  name: 'Products',
  props: {
  },
  created() {
    Promise.all([
      api.get('/product/active'),
      api.get('/picture/products'),
    ])
      .then(([products, pictures]) => {
        this.products = products.data.data
        this.pictures = pictures.data
      })
  },
  data () {
    return {
      language: this.$store.getters.language,
      products: [],
      pictures: []
    }
  },
  methods: {
    open_card(code) {
      this.$router.push({
        name: 'card',
        params: {
          code: code
        }
      })
    },
    has_picture(item) {
      if (item.pictures && item.pictures.length > 0) {
        return true
      }
      return false
    },
    get_picture(hash) {
      for (let index in this.pictures) {
        let picture = this.pictures[index]
        if (picture.hash && picture['hash'] === hash) {
          return 'data:;base64,' + picture['data']
        }
      }
      return null
    },
    get_name(item) {
      if (this.language === 'en-US') {
        return item.name_eng
      }
      if (this.language === 'et-EE') {
        return item.name_est
      }
      return item.name_rus
    }
  }
}
</script>

