<template>
  <section class="body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full p-8 rounded">
            <img alt="gallery" class="w-full h-full object-cover object-center block" src="@/assets/big_1.jpeg">
          </div>
        </div>
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full p-8 rounded">
            <img alt="gallery" class="w-full h-full object-cover object-center block" src="@/assets/big_2.jpeg">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainBlock',
  props: {
  }
}
</script>

