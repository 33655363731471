<template>
  <section class="text-gray-600 body-font overflow-hidden">
    <div class="container px-5 py-24 mx-auto">
      <div class="lg:w-4/5 mx-auto flex flex-wrap">
        <img v-if="this.pictures_data[this.current_picture]"
             class="lg:w-1/2 w-full lg:h-auto h-120 object-contain object-center rounded"
             :src="get_picture(this.pictures_data[this.current_picture].data)" :alt="this.pictures_data[this.current_picture].name"
        >
        <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
          <h2 class="text-sm title-font text-gray-500 tracking-widest" v-if="product.product_type === 'BOX'"
          >{{ $t('card.boxes') }}</h2>
          <h2 class="text-sm title-font text-gray-500 tracking-widest" v-if="product.product_type === 'BOUQUET'"
          >{{ $t('card.bouquets') }}</h2>
          <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{{ get_name() }}</h1>
          <p class="leading-relaxed">
            {{ get_description() }}
          </p>
          <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
            <div class="flex">
              <img class="lg:w-1/3 w-1/3 object-center object-contain rounded"
                   :key="index" v-for="(picture, index) in this.pictures_data"
                   @click="set_current_picture(index)"
                   :src="get_picture(picture.data)" :alt="picture.name"
              >
            </div>
          </div>
          <div class="flex">
            <span class="title-font font-medium text-2xl text-gray-900">€ {{ parseFloat(product.price).toFixed(2) }}</span>
            <button
                class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                @click="open_order(product.code)">{{ $t('card.order') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api'

const getData = (code, next) => {
  Promise.all([
    api.get(`/product/${code}`)
  ])
    .then(([product]) => {
      switch (product.data.product_type) {
        case 'ProductType.BOX': {
          product.data.product_type = 'BOX'
          break
        }
        case 'ProductType.BOUQUET': {
          product.data.product_type = 'BOUQUET'
          break
        }
      }
      switch (product.data.status) {
        case 'ProductStatus.ACTIVE': {
          product.data.status = 'ACTIVE'
          break
        }
        case 'ProductStatus.HIDDEN': {
          product.data.status = 'HIDDEN'
          break
        }
        case 'ProductStatus.CLOSED': {
          product.data.status = 'CLOSED'
          break
        }
      }
      let pictures_data = []
      if (!product.data.pictures) {
        product.data.pictures = []
      } else {
        product.data.pictures.forEach((value) => {
          api.get(`/picture/${value}`).then(response => {
            pictures_data.push(response.data)
          })
        })
      }
      next({
        product: product.data,
        pictures_data: pictures_data
      })
    })
}

export default {
  name: 'Card',
  data () {
    return {
      language: this.$store.getters.language,
      current_picture: 0,
      picture: null,
      pictures_data: [],
      product: {
        product_type: null,
        status: null,
        code: null,
        name_rus: null,
        name_eng: null,
        name_est: null,
        description_rus: null,
        description_eng: null,
        description_est: null,
        pictures: [],
        price: null
      }
    }
  },
  props: {},
  methods: {
    set_current_picture(index) {
      this.current_picture = index
    },
    get_name() {
      if (this.language === 'en-US') {
        return this.product.name_eng
      }
      if (this.language === 'et-EE') {
        return this.product.name_est
      }
      return this.product.name_rus
    },
    get_description() {
      if (this.language === 'en-US') {
        return this.product.description_eng
      }
      if (this.language === 'et-EE') {
        return this.product.description_est
      }
      return this.product.description_rus
    },
    get_picture(data) {
      return 'data:;base64,' + data;
    },
    open_order(code) {
      this.$router.push({
        name: 'order',
        params: {
          code: code
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    getData(to.params.code, data => {
      next(vm => {
        Object.assign(vm.$data, data)
      })
    })
  },
  beforeRouteUpdate (to, from, next) {
    getData(to.params.code, data => {
      Object.assign(this.$data, data)
      next()
    })
  }
}
</script>

