<template>
  <div class="font-sans antialiased bg-grey-lightest">
    <div class="bg-red-100 border border-red-400 text-red-700 top-20 px-4 py-3 rounded relative" role="alert" v-if="fail">
      <span class="block sm:inline">{{ $t('order.fail') }}</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer">
          <svg class="fill-current h-6 w-6 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="close_fail">
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
          </svg>
        </span>
    </div>
    <div class="bg-green-100 border border-green-400 text-green-700 top-20 px-8 py-3 rounded relative" role="alert" v-if="success">
      <span class="block sm:inline">{{ $t('order.success') }}</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer">
        <svg class="fill-current h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="close_success">
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
        </svg>
      </span>
    </div>
    <div class="w-full bg-grey-lightest" style="padding-top: 4rem;">
      <div class="container mx-auto py-8">
        <div class="w-5/6 lg:w-1/2 mx-auto bg-white rounded shadow">
          <div class="py-4 px-6 text-black text-xl bg-gray-200">
            <font-awesome-icon :icon="['fas', 'leaf']" size="1x" />
            {{ $t('order.product') }}
          </div>
          <div class="py-4 px-8">
            <div class="flex mb-4">
              <div class="w-2/3 mr-1">
                <label class="block text-grey-darker text-base font-bold mb-2">{{ $t('order.name') }}</label>
                <label class="block text-grey-darker text-base mb-2">{{ get_name() }}</label>
                <label class="block text-grey-darker text-base font-bold mb-2">{{ $t('order.description') }}</label>
                <label class="block text-grey-darker text-base text-justify mb-2">{{ get_description() }}</label>
              </div>
              <div class="w-1/3 ml-1">
                <img
                    class="lg:h-48 md:h-36 w-full object-contain object-center"
                    v-if="this.pictures_data[0]"
                    :src="get_picture(this.pictures_data[0].data)"
                    :alt="this.pictures_data[0].name">
              </div>
            </div>
          </div>

          <div class="py-4 px-8 text-black text-xl bg-gray-200">
            <font-awesome-icon :icon="['fas', 'truck']" size="1x"/>
            {{ $t('order.delivery') }}
          </div>

          <div class="py-4 px-8">
            <div class="flex mb-4">
              <div class="w-1/2 mr-1">
                <label class="block text-grey-darker text-base font-bold mb-2" for="phone">{{ $t('order.phone')}}</label>
                <input
                    class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="phone"
                    name="phone"
                    v-if="!order_created"
                    v-model.trim="$v.phone.$model"
                    :class="[get_color($v.phone.required, $v.phone.minLength),
                    get_focus_color($v.phone.required, $v.phone.minLength)]"
                    type="text"
                    :placeholder="$t('order.phone')">
                <label v-if="order_created">{{ phone }}</label>
              </div>
              <div class="w-1/2 ml-1">
                <label class="block text-grey-darker text-base font-bold mb-2"
                       for="person_name">{{ $t('order.person_name') }}</label>
                <input
                    class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="person_name"
                    name="person_name"
                    v-if="!order_created"
                    v-model.trim="$v.person_name.$model"
                    :class="[get_color($v.person_name.required, $v.person_name.minLength),
                    get_focus_color($v.person_name.required, $v.person_name.minLength)]"
                    type="text"
                    :placeholder="$t('order.person_name')">
                <label v-if="order_created">{{ person_name }}</label>
              </div>
            </div>

            <div class="mb-4">
              <label class="block text-grey-darker text-base font-bold mb-2">{{ $t(getDateLabel())}}</label>
              <label class="block text-grey-darker text-base mb-2">{{ deliveryDate }}</label>
              <v-calendar
                class="bg-red-100"
                v-model.trim="$v.deliveryDate.$model"
                :class="[get_color($v.deliveryDate.required, $v.deliveryDate.minLength),
                get_focus_color($v.deliveryDate.required, $v.deliveryDate.minLength)]"
                :locale="language"
                v-if="!order_created"
                :attributes="attributes"
                :first-day-of-week="2"
                @dayclick="onDayClick"
                :min-date='min_date'>
              </v-calendar>
            </div>
            <div class="mb-4">
                <label class="block text-grey-darker text-base font-bold mb-2">{{ $t(getTimeLabel())}}</label>
                <div class="relative inline-block text-left">
                  <div @click="toggleTimeList" v-if="!order_created">
                    <button type="button" class="inline-flex justify-center w-full rounded-md border
                    border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 bg-green-100
                    hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true"
                            aria-haspopup="true">
                      {{ timeRange }}
                      <font-awesome-icon
                          :icon="['fas', timeList ? 'chevron-up' : 'chevron-down']"
                          size="xs"
                          class="-mr-1 ml-2 h-6 w-5"/>
                    </button>
                  </div>
                  <div
                      class="origin-top-right absolute right-0 mt-2 w-36 rounded-md z-40
                      shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                      v-if="timeList && !order_created">
                    <div class="py-1" role="none">
                      <a v-for="(range, idx) in timeRanges" :key="'range-'+idx"
                         href="#"
                         class="text-gray-700 block px-4 py-2 text-base"
                         :class="isActiveTimeRange(range)"
                         role="menuitem"
                         tabindex="-1"
                         :id="'range-'+idx"
                         @click="setTimeRange(range)">{{ range }}
                      </a>
                    </div>
                  </div>
                </div>
                <label v-if="order_created">{{ timeRange }}</label>
            </div>

            <div class="mb-4">
              <label class="block text-grey-darker text-base font-bold mb-2"
                     for="additional_info">{{ $t('order.additional_info') }} ({{ $t('order.optional') }})</label>
              <textarea
                  id="additional_info"
                  name="additional_info"
                  v-if="!order_created"
                  v-model="additional_info"
                  class="w-full bg-opacity-50 rounded border border-gray-300 bg-green-100
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base
                  outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out">
              </textarea>
              <label v-if="order_created">{{ additional_info }}</label>
            </div>

            <div class="mb-4">
              <label class="block text-grey-darker text-base font-bold mb-2" for="greeting">{{ $t('order.message') }}
                ({{ $t('order.optional') }})</label>
              <textarea
                  id="greeting"
                  name="greeting"
                  v-model="greeting"
                  v-if="!order_created"
                  class="w-full bg-opacity-50 rounded border border-gray-300 bg-green-100
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base
                  outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out">
              </textarea>
              <label v-if="order_created">{{ greeting }}</label>
            </div>

            <div class="flex mb-4">
              <div>
                <label class="block text-grey-darker text-base font-bold mb-2">{{ $t('order.delivery_type') }}</label>
                <p class="text-base text-gray-500" v-if="!order_created">{{ $t('order.please_choose') }}</p>
              </div>
            </div>

            <div class="flex mb-4">
              <section class="text-gray-600 body-font" v-if="!order_created">
                <div class="container mx-auto flex flex-wrap">
                  <div class="flex flex-wrap -m-4">
                    <div class="p-4 lg:w-1/2 md:w-full flex">
                      <div
                          class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col cursor-pointer"
                          :class="isActiveDeliveryType('pickup')"
                          @click="toggleDeliveryType('pickup')">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                          <font-awesome-icon :icon="['fas', 'dolly']" size="lg"/>
                        </div>
                        <div class="flex-grow">
                          <h2 class="text-gray-900 text-lg title-font font-medium mb-3">{{ $t('order.pickup') }}</h2>
                          <p class="leading-relaxed text-base">{{ $t('order.pickup_text') }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/2 md:w-full flex">
                      <div
                          class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col cursor-pointer"
                          :class="isActiveDeliveryType('courier')"
                          @click="toggleDeliveryType('courier')">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                          <font-awesome-icon :icon="['fas', 'truck']" size="lg"/>
                        </div>
                        <div class="flex-grow">
                          <h2 class="text-gray-900 text-lg title-font font-medium mb-3">{{ $t('order.courier') }}</h2>
                          <p class="leading-relaxed text-base">{{ $t('order.courier_text') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <label v-if="order_created">{{ $t('order.' + deliveryType) }}</label>
            </div>

            <div class="mb-4" v-if="deliveryType==='courier'">
                <label class="block text-grey-darker text-base font-bold mb-2" for="address">{{ $t('order.address') }}</label>
                <input
                    class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="address"
                    name="address"
                    v-if="!order_created"
                    v-model.trim="$v.address.$model"
                    :class="[get_color($v.address.required, $v.address.minLength),
                    get_focus_color($v.address.required, $v.address.minLength)]"
                    type="text"
                    :placeholder="$t('order.address')">
                <label v-if="order_created">{{ address }}</label>
            </div>

            <div class="mb-4" v-if="deliveryType==='courier'">
                <label class="block text-grey-darker text-base font-bold mb-2">{{ $t('order.settlement') }}</label>
                <div class="relative" v-if="!order_created">
                  <div @click="toggleSettlementList">
                    <button type="button"
                            :class="isSettlementChoose()"
                            class="inline-flex justify-left w-full rounded-md border bg-green-100
                    border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700
                    hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-offset-gray-100 focus:ring-indigo-500"
                            id="menu-button-settlement"
                            aria-expanded="true"
                            aria-haspopup="true">
                      {{ $t('order.' + settlement) }}
                      <!--<font-awesome-icon
                          :icon="['fas', settlementList ? 'chevron-up' : 'chevron-down']"
                          size="xs"
                          class="-mr-1 ml-2 h-6 w-5 justify-right"/>-->
                    </button>
                  </div>
                  <div
                      class="origin-top-right absolute right-0 mt-2 w-full rounded-md z-40
                      shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                      v-if="settlementList">
                    <div class="py-1" role="none">
                      <a v-for="(settlement, idx) in settlements" :key="'settlement-'+idx"
                         href="#"
                         class="text-gray-700 block px-4 py-2 text-base"
                         :class="isActiveSettlement(settlement.name)"
                         role="menuitem"
                         tabindex="-1"
                         :id="'settlement-'+idx"
                         @click="setSettlement(settlement.name)">{{ $t('order.' + settlement.name) }}
                      </a>
                    </div>
                  </div>
                </div>
                <label v-if="order_created">{{ settlement }}</label>
              </div>
          </div>

          <div class="py-4 px-8 text-black text-xl bg-gray-200">
            <font-awesome-icon :icon="['fas', 'euro-sign']" size="1x"/>
            {{ $t('order.buyer') }}
          </div>
          <div class="py-4 px-8">
            <div class="flex mb-4">
              <div class="w-1/2 mr-1">
                <label class="block text-grey-darker text-base font-bold mb-2" for="buyer_phone">{{
                    $t('order.phone')
                  }}</label>
                <input
                    class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="buyer_phone"
                    name="buyer_phone"
                    v-if="!order_created"
                    v-model.trim="$v.buyer_phone.$model"
                    :class="[get_color($v.buyer_phone.required, $v.buyer_phone.minLength), get_focus_color($v.buyer_phone.required, $v.buyer_phone.minLength)]"
                    type="text"
                    :placeholder="$t('order.phone')">
                <label v-if="order_created">{{ buyer_phone }}</label>
              </div>
              <div class="w-1/2 ml-1">
                <label class="block text-grey-darker text-base font-bold mb-2" for="buyer_email">{{ $t('order.email') }}</label>
                <input
                    class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="buyer_email"
                    name="buyer_email"
                    v-if="!order_created"
                    v-model.trim="$v.buyer_email.$model"
                    :class="[get_color($v.buyer_email.required, $v.buyer_email.email), get_focus_color($v.buyer_email.required, $v.buyer_email.email)]"
                    type="email"
                    :placeholder="$t('order.email')">
                <label v-if="order_created">{{ buyer_email }}</label>
              </div>
            </div>
          </div>

          <div class="py-4 px-8 text-black text-xl bg-gray-200">
            <font-awesome-icon :icon="['fas', 'credit-card']" size="1x"/>
            {{ $t('order.payment') }}
          </div>
          <div class="py-4 px-8">
            <table class="table-fixed w-full">
              <tbody>
              <tr>
                <td class="border px-4 py-2">{{ $t('order.' + product.product_type) }} ({{ get_name() }})</td>
                <td class="border px-4 py-2 text-right">{{ parseFloat(product.price).toFixed(2) }} €</td>
              </tr>
              <tr class="bg-gray-100">
                <td class="border px-4 py-2">{{ $t('order.delivery') }} ({{ $t('order.' + deliveryType) }})</td>
                <td class="border px-4 py-2 text-right">{{ getDeliveryPrice() }} €</td>
              </tr>
              <tr>
                <td class="border px-4 py-2 font-bold">{{ $t('order.total') }}</td>
                <td class="border px-4 py-2 font-bold text-right">
                  {{ getTotalPrice() }} €
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="py-4 px-8" v-if="paymentMethod !== 'undefined'">
            <div class="w-full">
              <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" v-if="!order_created">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a class="text-sm font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                     v-on:click="togglePaymentMethod('bank_account')"
                     v-bind:class="{'text-grey-600 bg-grey': paymentMethod !== 'bank_account', 'text-white bg-gray-600':
                     paymentMethod === 'bank_account'}">
                    <font-awesome-icon :icon="['fas', 'piggy-bank']" class="text-base mr-1"/>
                    {{ $t('order.bank_account') }}
                  </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a class="text-sm font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                     v-on:click="togglePaymentMethod('credit_card')"
                     v-bind:class="{'text-greay-600 bg-grey': paymentMethod !== 'credit_card', 'text-white bg-gray-600':
                     paymentMethod === 'credit_card'}">
                    <font-awesome-icon :icon="['fas', 'credit-card']" class="text-base mr-1"/>
                    {{ $t('order.credit_card') }}
                  </a>
                </li>
              </ul>
              <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" v-if="order_created">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="paymentMethod==='bank_account'">
                  <a class="text-sm font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                     v-on:click="togglePaymentMethod('bank_account')"
                     v-bind:class="{'text-grey-600 bg-grey': paymentMethod !== 'bank_account', 'text-white bg-gray-600':
                     paymentMethod === 'bank_account'}">
                    <font-awesome-icon :icon="['fas', 'piggy-bank']" class="text-base mr-1"/>
                    {{ $t('order.bank_account') }}
                  </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="paymentMethod==='credit_card'">
                  <a class="text-sm font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                     v-on:click="togglePaymentMethod('credit_card')"
                     v-bind:class="{'text-greay-600 bg-grey': paymentMethod !== 'credit_card', 'text-white bg-gray-600':
                     paymentMethod === 'credit_card'}">
                    <font-awesome-icon :icon="['fas', 'credit-card']" class="text-base mr-1"/>
                    {{ $t('order.credit_card') }}
                  </a>
                </li>
              </ul>
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div class="px-4 py-5 flex-auto">
                  <div class="tab-content tab-space">
                    <div
                        v-bind:class="{'hidden': paymentMethod !== 'bank_account', 'block': paymentMethod === 'bank_account'}">
                      <p>
                        {{ $t('order.bank_account_text_1') }} <b>{{
                          getTotalPrice()
                        }}€</b>
                        {{ $t('order.bank_account_text_2') }} <b>EE327700771001760429</b> LHV Bank, Construction Yard
                        OÜ.
                        {{ $t('order.bank_account_text_3') }} <b>{{ orderNumber }}</b>
                        {{ $t('order.bank_account_text_4') }} <b>{{ $t('order.' + product.product_type) }} ({{ get_name() }})</b>.
                      </p>
                    </div>
                    <div
                        v-bind:class="{'hidden': paymentMethod !== 'credit_card', 'block': paymentMethod === 'credit_card'}">
                      <elements :stripe="$stripe.stripe" :options="{ locale: language }">
                        <elements-consumer>
                          <template v-slot="{stripe, elements}">
                            <card-element
                              v-if="elements"
                              @change="ccCompleted = $event.complete"
                              @ready="card = $event" />
                          </template>
                        </elements-consumer>
                      </elements>

                      <div class="bg-red-100 border border-red-400 text-red-700 top-20 px-4 py-3 rounded relative" role="alert" v-if="card_fail">
                        <span class="block sm:inline">{{ card_fail }}</span>
                        <span class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer">
                          <svg class="fill-current h-6 w-6 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="close_card_fail">
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="py-4 px-8">
            <div class="p-2 w-full" v-if="!order_created">
              <button
                  :class="order_disabled ? 'disabled:opacity-50' : ''"
                  class="flex mx-auto text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-500 rounded text-lg"
                  :disabled="order_disabled ? 'disabled' : null"
                  @click="make_order('NEW')"
                  v-if="paymentMethod==='bank_account' || paymentMethod==='undefined'">
                {{ $t('order.order') }}
              </button>
              <button
                  :class="pay_disabled ? 'disabled:opacity-50' : ''"
                  class="flex mx-auto text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-500 rounded text-lg"
                  :disabled="pay_disabled ? 'disabled' : null"
                  @click="make_payment()"
                  v-if="paymentMethod==='credit_card'">
                {{ $t('order.pay') }} {{ getTotalPrice() }}€
              </button>
            </div>
            <div class="p-2 w-full" v-if="order_created">
              <button
                  class="flex mx-auto text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-500 rounded text-lg"
                  @click="to_main_page()">
                {{ $t('order.main_page') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import {required, minLength, email} from 'vuelidate/lib/validators'
import moment from 'moment'
import { CardElement, ElementsConsumer } from "@gourmetpro/vue-stripe-js";
import { Elements } from "@gourmetpro/vue-stripe-js";

const getData = (code, next) => {
  Promise.all([
    api.get(`/product/${code}`),
    api.get(`/settings`)
  ])
      .then(([product, settings]) => {
        switch (product.data.product_type) {
          case 'ProductType.BOX': {
            product.data.product_type = 'BOX'
            break
          }
          case 'ProductType.BOUQUET': {
            product.data.product_type = 'BOUQUET'
            break
          }
        }
        switch (product.data.status) {
          case 'ProductStatus.ACTIVE': {
            product.data.status = 'ACTIVE'
            break
          }
          case 'ProductStatus.HIDDEN': {
            product.data.status = 'HIDDEN'
            break
          }
          case 'ProductStatus.CLOSED': {
            product.data.status = 'CLOSED'
            break
          }
        }
        let pictures_data = []
        if (!product.data.pictures) {
          product.data.pictures = []
        } else {
          product.data.pictures.forEach((value) => {
            api.get(`/picture/${value}`).then(response => {
              pictures_data.push(response.data)
            })
          })
        }
        next({
          product: product.data,
          pictures_data: pictures_data,
          DATE_TO_ADD: settings.data.data.delivery_days
        })
      })
}

export default {
  name: "Order",
  components: {
    CardElement,
    ElementsConsumer,
    Elements
  },
  data() {
    return {
      timeRanges: [],
      card_fail: null,
      ccCompleted: false,
      card: null,
      payment_token: null,
      DATE_TO_ADD: 2,
      language: this.$store.getters.language,
      timeRange: '09:00-11:00',
      timeList: false,
      paymentMethod: 'bank_account',
      orderNumber: this.random_string(),
      deliveryDate: null,
      deliveryType: 'pickup',
      pictures_data: [],
      deliveryPrice: 0,
      phone: null,
      person_name: null,
      address: null,
      buyer_phone: null,
      buyer_email: null,
      greeting: null,
      additional_info: null,
      settlement: 'TALLINN',
      settlementList: false,
      weekday: null,
      order_created: false,
      fail: false,
      success: false,
      settlements: [
        { name: 'TALLINN', price: 7 },
        { name: 'TALLINN_NEARBY', price: 11 }
      ],
      product: {
        product_type: null,
        status: null,
        code: null,
        name_rus: null,
        name_eng: null,
        name_est: null,
        description_rus: null,
        description_eng: null,
        description_est: null,
        pictures: [],
        price: null
      },
      attributes: []
    }
  },
  mounted() {
    let nextDate = moment().add(this.DATE_TO_ADD, 'd')
    this.deliveryDate = nextDate.format('YYYY-MM-DD')
    this.weekday = nextDate.isoWeekday()

    this.attributes.push({
      key: 'deliveryDate',
      highlight: true,
      dates: this.deliveryDate,
    })
    let startHour = 9;
    for (let i = startHour; i < 21; i += 2) {
      let nextHour = i + 2
      this.timeRanges.push(i.toString().padStart(2, '0') + ":00-" + nextHour.toString() + ":00")
    }
    this.timeRange = '09:00-11:00'
  },
  computed: {
    min_date() {
      return moment().add(this.DATE_TO_ADD, 'd').format('YYYY-MM-DD')
    },
    is_weekend() {
      return this.weekday === 1 || this.weekday === 7
    },
    order_disabled() {
      return !(
          this.get_status(this.$v.phone.required, this.$v.phone.minLength)
          && this.get_status(this.$v.person_name.required, this.$v.person_name.minLength)
          && this.get_status(this.$v.buyer_phone.required, this.$v.buyer_phone.minLength)
          && this.get_status(this.$v.buyer_email.required, this.$v.buyer_email.email)
          && this.get_status(this.$v.deliveryDate.required, this.$v.deliveryDate.minLength)
          && this.timeRange
      )
    },
    pay_disabled() {
      return !(
          this.get_status(this.$v.phone.required, this.$v.phone.minLength)
          && this.get_status(this.$v.person_name.required, this.$v.person_name.minLength)
          && this.get_status(this.$v.buyer_phone.required, this.$v.buyer_phone.minLength)
          && this.get_status(this.$v.buyer_email.required, this.$v.buyer_email.email)
          && this.get_status(this.$v.deliveryDate.required, this.$v.deliveryDate.minLength)
          && this.timeRange && this.ccCompleted
      )
    }
  },
  methods: {
    close_card_fail () {
      this.card_fail = null
    },
    finalize_payment (payment_token) {
      this.card_fail = null
      this.payment_token = payment_token
      this.make_order('PAID')
    },
    make_payment () {
      const paymentMethodData = {
        type: "card",
        card: this.card,
        billing_details: {
          phone: this.buyer_phone,
          email: this.buyer_email
        }
      }
      this.$stripe.stripe.createPaymentMethod(paymentMethodData).then(result => {
        if (result.error) {
          this.card_fail = result.error.message
        } else {
          api.post('/payment/make_payment', {
            'payment_method_id': result.paymentMethod.id,
            'sum': this.getTotalPrice()
          }).then(resp => {
            if (resp.data.payment.status === 'requires_confirmation' || resp.data.payment.status === 'requires_action') {
              this.$stripe.stripe.confirmCardPayment(
                  resp.data.payment.client_secret,
                  {
                    payment_method: paymentMethodData
                  }, {
                    setup_future_usage: 'off_session'
                  }
              ).then(resp => {
                if (resp.paymentIntent && resp.paymentIntent.status === 'succeeded') {
                  this.finalize_payment(resp.paymentIntent.id)
                } else {
                  this.card_fail = resp.error.message
                }
              })
            } else {
              this.finalize_payment(resp.data.payment.id)
            }
          }).catch(error => {
            this.card_fail = error
          })
        }
      });
    },
    to_main_page() {
      this.$router.push({name: 'home'})
    },
    close_fail() {
      this.fail = false;
    },
    close_success() {
      this.success = false;
    },
    getDeliveryPrice() {
      if (this.paymentMethod === 'undefined') {
        return '(' + this.$i18n.t('order.by_agreement') + ')'
      }
      return parseFloat(this.deliveryPrice).toFixed(2)
    },
    getTotalPrice() {
      if (this.paymentMethod === 'undefined') {
        return '(' + this.$i18n.t('order.by_agreement') + ')'
      }
      return parseFloat(this.product.price + this.deliveryPrice).toFixed(2)
    },
    getDateLabel() {
      if (this.deliveryType === 'pickup') {
        return 'order.receiving_date'
      }
      return 'order.delivery_date'
    },
    getTimeLabel() {
      if (this.deliveryType === 'pickup') {
        return 'order.receiving_time'
      }
      return 'order.delivery_time'
    },
    get_status(required, min_length) {
      if (!required && min_length) {
        return false
      }
      return min_length;
    },
    get_color(required, min_length) {
      if (!required && min_length) {
        return 'bg-gray-100'
      }
      if (!min_length) {
        return 'bg-red-100'
      }
      return 'bg-green-100'
    },
    get_focus_color(required, min_length) {
      if (!required && min_length) {
        return 'focus:bg-red-100'
      }
      if (!min_length) {
        return 'focus:bg-red-100'
      }
      return 'focus:bg-green-100'
    },
    calculateDeliveryPrice() {
      if (this.deliveryType === 'pickup') {
        this.deliveryPrice = 0
      }
      if (this.deliveryType === 'courier') {
        for (let index in this.settlements) {
          if (this.settlements[index].name === this.settlement) {
            this.deliveryPrice = this.settlements[index].price
          }
        }
      }
    },
    random_string() {
      const list = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      let res = "";
      for(let i = 0; i < 12; i++) {
        let rnd = Math.floor(Math.random() * list.length);
        res = res + list.charAt(rnd);
      }
      return res;
    },
    onDayClick(day) {
      if (day.id >= this.min_date) {
        this.attributes.splice(0)
        this.weekday = day.weekday
        this.deliveryDate = day.id
        this.attributes.push({
          key: 'deliveryDate',
          highlight: true,
          dates: new Date(this.deliveryDate)
        })
      }
    },
    isSettlementChoose(deliveryType) {
      if (this.deliveryType === deliveryType) {
        return "bg-green-100"
      }
      return ''
    },
    isActiveDeliveryType(deliveryType) {
      if (this.deliveryType === deliveryType) {
        return "bg-green-100"
      }
      return ''
    },
    isActiveTimeRange(range) {
      if (this.timeRange === range) {
        return "bg-gray-100 text-gray-900"
      }
      return "text-gray-700"
    },
    setTimeRange(range) {
      this.timeRange = range
      this.timeList = false
    },
    isActiveSettlement(settlement) {
      if (this.settlement === settlement) {
        return "bg-gray-100 text-gray-900"
      }
      return "text-gray-700"
    },
    setSettlement(settlement) {
      this.settlement = settlement
      this.calculateDeliveryPrice()
      this.settlementList = false
    },
    toggleDeliveryType(deliveryType) {
      this.deliveryType = deliveryType
      this.calculateDeliveryPrice()
    },
    toggleTimeList() {
      this.timeList = !this.timeList
    },
    toggleSettlementList() {
      this.settlementList = !this.settlementList
    },
    togglePaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod
    },
    get_name() {
      if (this.language === 'en-US') {
        return this.product.name_eng
      }
      if (this.language === 'et-EE') {
        return this.product.name_est
      }
      return this.product.name_rus
    },
    get_description() {
      if (this.language === 'en-US') {
        return this.product.description_eng
      }
      if (this.language === 'et-EE') {
        return this.product.description_est
      }
      return this.product.description_rus
    },
    get_picture(data) {
      return 'data:;base64,' + data;
    },
    send_admin_email(email, order_data) {
      api.post('/email/' + email, { ...order_data,
        product_name: this.product.name_rus,
        product_description: this.product.description_rus,
        delivery_type: this.$i18n.t('order.' + this.deliveryType),
        payment_type: this.$i18n.t('order.' + this.paymentMethod),
        district: this.$i18n.t('order.' + this.settlement),
        link_to_product: location.protocol + '//' + location.host + '/card/' + this.product.code
      }).then(() => {}).catch(() => {})
    },
    send_customer_email(email, order_data) {
      api.post('/email/' + email, { ...order_data,
        product_name: this.get_name(),
        product_description: this.get_description(),
        delivery_type: this.$i18n.t('order.' + this.deliveryType),
        payment_type: this.$i18n.t('order.' + this.paymentMethod),
        district: this.$i18n.t('order.' + this.settlement),
        customer_language: this.language.substring(0, 2)
      }).then(() => {}).catch(() => {})
    },
    make_order(status) {
      let order_data = {
        code: this.orderNumber,
        phone: this.phone,
        name: this.person_name,
        delivery_date: this.deliveryDate,
        delivery_time: this.timeRange,
        additional: this.additional_info ? this.additional_info : '',
        greeting: this.greeting ? this.greeting : '',
        address: this.address ? this.address : '',
        district: (this.settlement && this.deliveryType === 'courier') ? this.settlement : '',
        email: this.buyer_email,
        buyer_phone: this.buyer_phone,
        product_price: parseFloat(this.product.price).toFixed(2),
        delivery_price: this.getDeliveryPrice(),
        total_price: this.getTotalPrice(),
      }
      api.post('/delivery/', { ...order_data,
        status: status,
        product_code: this.product.code,
        delivery_type: this.deliveryType.toUpperCase(),
        payment_type: this.paymentMethod === 'bank_account' ? 'BANK_TRANSFER' : 'STRIPE',
        payment_token: this.payment_token ? this.payment_token : '',
        customer_language: this.language.substring(0, 2)
      }).then(() => {
        this.send_admin_email('order_admin_email', order_data)
        this.send_customer_email('order_customer_email', order_data)
        if (status === 'PAID') {
          this.send_admin_email('order_paid_admin_email', order_data)
          this.send_customer_email('order_paid_customer_email', order_data)
        }
        this.success = true
        this.order_created = true
      }).catch(() => {
        this.fail = true
      })
    }
  },
  validations: {
    phone: {
      required,
      minLength: minLength(6)
    },
    person_name: {
      required,
      minLength: minLength(2)
    },
    address: {
      required,
      minLength: minLength(8)
    },
    buyer_phone: {
      required,
      minLength: minLength(6)
    },
    buyer_email: {
      required,
      email
    },
    deliveryDate: {
      required,
      minLength: minLength(10)
    },
  },
  beforeRouteEnter(to, from, next) {
    getData(to.params.code, data => {
      next(vm => {
        Object.assign(vm.$data, data)
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    getData(to.params.code, data => {
      Object.assign(this.$data, data)
      next()
    })
  }
}
</script>

