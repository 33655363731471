<template>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {{ $t('policy.header') }}
        </h2>
      </div>
    </div>
    <div class="max-w-screen-xl sm:mx-auto">
      <div class="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-1">
        <div class="space-y-16">
          <div>
            <p class="text-gray-700">
              {{ $t('policy.1_1') }}<br/><br/>
              {{ $t('policy.1_2') }}<br/><br/>
              {{ $t('policy.1_3') }}<br/><br/>
              {{ $t('policy.1_4') }}<br/>
              {{ $t('policy.1_4_1') }}<br/>
              {{ $t('policy.1_4_2') }}<br/>
              {{ $t('policy.1_4_3') }}<br/>
              {{ $t('policy.1_4_4') }}<br/>
              {{ $t('policy.1_4_5') }}<br/><br/>
              {{ $t('policy.1_5') }}<br/>
              {{ $t('policy.1_5_1') }}<br/>
              {{ $t('policy.1_5_2') }}<br/><br/>
              {{ $t('policy.1_6') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Policy',
  props: {}
}
</script>

