<template>
  <header class="bg-gray-100 fixed inset-x-0 z-50">
    <div class="max-w-6xl mx-auto px-4">
      <div class="flex justify-between">
        <div class="flex space-x-4">
          <!-- logo -->
          <div>
            <a href="/" class="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900">
              <i class='bx bxl-medium-old mr-1 text-xl mb-1 text-blue-400'></i> <span class="font-bold">BOUQUET</span>
            </a>
          </div>
          <!-- primary nav -->
          <div class="hidden md:flex items-center space-x-1">
            <a href="/" class="py-5 px-3 text-gray-700 hover:text-gray-900">{{ $t('header.home') }}</a>
            <router-link :to="{name: 'bouquets'}" class="py-5 px-3 text-gray-700 hover:text-gray-900">
              {{ $t('header.bouquets') }}
            </router-link>
            <router-link :to="{name: 'boxes'}" class="py-5 px-3 text-gray-700 hover:text-gray-900">
              {{ $t('header.boxes') }}
            </router-link>
          </div>
          <div class="md:hidden items-center flex">
            <router-link :to="{name: 'bouquets'}" class="block py-2 px-2 text-sm hover:bg-gray-200">
              {{ $t('header.bouquets') }}
            </router-link>
            <router-link :to="{name: 'boxes'}" class="block py-2 px-2 text-sm hover:bg-gray-200">
              {{ $t('header.boxes') }}
            </router-link>
          </div>
          <div class="md:hidden items-center flex">
            <a href=""
               v-if="language==='ru-RU'"
               class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
               @click="set_language('ru-RU')"
            >
              RU
            </a>
            <a href=""
               v-else
               class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
               @click="set_language('ru-RU')"
            >
              RU
            </a>
            <a href=""
               v-if="language==='et-EE'"
               class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
               @click="set_language('et-EE')"
            >
              ET
            </a>
            <a href=""
               v-else
               class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
               @click="set_language('et-EE')"
            >
              ET
            </a>
            <a href=""
               v-if="language==='en-US'"
               class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
               @click="set_language('et-EE')"
            >
              EN
            </a>
            <a href=""
               v-else
               class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
               @click="set_language('en-US')"
            >
              EN
            </a>
          </div>
        </div>
        <!-- secondary nav -->
        <div class="hidden md:flex items-center space-x-1">
          <a href=""
             v-if="language==='ru-RU'"
             class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
             @click="set_language('ru-RU')"
          >
            RU
          </a>
          <a href=""
             v-else
             class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
             @click="set_language('ru-RU')"
          >
            RU
          </a>
          <a href=""
             v-if="language==='et-EE'"
             class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
             @click="set_language('et-EE')"
          >
            ET
          </a>
          <a href=""
             v-else
             class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
             @click="set_language('et-EE')"
          >
            ET
          </a>
          <a href=""
             v-if="language==='en-US'"
             class="py-1 px-2 bg-gray-300 text-gray-800 text-sm rounded transition duration-400"
             @click="set_language('et-EE')"
          >
            EN
          </a>
          <a href=""
             v-else
             class="py-1 px-2 bg-gray-400 text-white hover:bg-gray-300 text-sm hover:text-gray-800 rounded transition duration-400"
             @click="set_language('en-US')"
          >
            EN
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      language: this.$store.getters.language
    }
  },
  mounted () {
    if (!localStorage.getItem('bouquet.language')) {
      localStorage.setItem('bouquet.language', 'et-EE')
      this.set_language('et-EE')
    }
    this.$i18n.locale = this.$store.getters.language
  },
  methods: {
    set_language (language) {
      this.$store.dispatch('setLanguage', language)
      this.language = language
      this.$i18n.locale = language
    }
  }
}
</script>
