<template>
  <section>
    <right-block></right-block>
    <main-block></main-block>
    <products></products>
    <instagram></instagram>
  </section>
</template>

<script>
import RightBlock from '@/components/RightBlock'
import MainBlock from '@/components/MainBlock'
import Products from '@/components/Products'
import Instagram from '@/components/Instagram'

export default {
  name: 'Home',
  components: {
    Instagram,
    Products,
    MainBlock,
    RightBlock
  },
  props: {
  }
}
</script>
