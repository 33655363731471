<template>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {{ $t('terms.header') }}
        </h2>
      </div>
    </div>
    <div class="max-w-screen-xl sm:mx-auto">
      <div class="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-1">
        <div class="space-y-16">
          <div>
            <p class="mb-4 text-xl font-medium">
              {{ $t('terms.subheader_1') }}
            </p>
            <p class="text-gray-700">
              {{ $t('terms.1_1') }}<br/><br/>
              {{ $t('terms.1_2') }}<br/><br/>
              {{ $t('terms.1_3') }}<br/><br/>
              {{ $t('terms.1_4') }}<br/><br/>
              {{ $t('terms.1_5') }}<br/><br/>
            </p>
            <p class="mb-4 text-xl font-medium">
              {{ $t('terms.subheader_2') }}
            </p>
            <p class="text-gray-700">
              {{ $t('terms.2_1') }}<br/><br/>
              {{ $t('terms.2_2') }}<br/><br/>
              {{ $t('terms.2_3') }}<br/><br/>
              {{ $t('terms.2_4') }}<br/><br/>
              {{ $t('terms.2_5') }}<br/><br/>
              {{ $t('terms.2_6') }}<br/><br/>
              {{ $t('terms.2_7') }}<br/><br/>
              {{ $t('terms.2_8') }}<br/><br/>
              {{ $t('terms.2_9') }}<br/><br/>
              {{ $t('terms.2_10') }}<br/><br/>
              {{ $t('terms.2_11') }}<br/><br/>
              {{ $t('terms.2_12') }}<br/><br/>
              {{ $t('terms.2_13') }}<br/><br/>
              {{ $t('terms.2_14') }}<br/><br/>
              {{ $t('terms.2_15') }}<br/><br/>
              {{ $t('terms.2_16') }}<br/><br/>
              {{ $t('terms.2_17') }}<br/><br/>
              {{ $t('terms.2_18') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  props: {}
}
</script>

