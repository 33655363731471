import i18n from './i18n'
import store from './store'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from "vuelidate"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCreditCard,
    faPiggyBank,
    faDolly,
    faTruck,
    faChevronDown,
    faChevronUp,
    faLeaf,
    faEuroSign,
    faPhotoVideo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueDayjs from 'vue-dayjs-plugin'
import VCalendar from 'v-calendar';
import { loadStripe } from "@stripe/stripe-js";
import VueStripe from "@gourmetpro/vue-stripe-js";
import VueGtag from "vue-gtag";
import config from "../config"

library.add(faCreditCard)
library.add(faPiggyBank)
library.add(faDolly)
library.add(faTruck)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faLeaf)
library.add(faEuroSign)
library.add(faPhotoVideo)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import './assets/styles/index.css';

Vue.config.productionTip = false

Vue.use(router)
Vue.use(Vuelidate)
Vue.use(VueDayjs)
Vue.use(VCalendar)

const stripePromise = loadStripe(config.STRIPE_FRONTEND_TOKEN);
Vue.use(VueStripe, { stripe: stripePromise });

Vue.use(VueGtag, {
    config: { id: config.GTAG_ID }
}, router);

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
