<template>
  <div class="px-4 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    <div class="flex flex-col text-center w-full">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-6 text-gray-900">
        {{ $t('instagram.photos') }}</h1>
    </div>
    <split-carousel :display-amount="6" :height="250" :item-width="200">
      <split-carousel-item v-for="(feed, index) in feeds" :key="index">
        <div class="bg-gray-100 rounded-lg">
          <a :href="feed.permalink">
            <img class="h-100 rounded w-full object-contain object-center mb-6" :src="feed.media_url" alt="content">
          </a>
        </div>
      </split-carousel-item>
    </split-carousel>
  </div>
</template>

<script>
import axios from 'axios'
import api from '@/api'

import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";

export default {
  name: 'Instagram',
  components: {
    SplitCarousel,
    SplitCarouselItem
  },
  props: {
  },
  mounted () {
    this.getUserFeed()
  },
  data: () => ({
    error: null,
    loading: false,
    feeds: [],
    mediatypes: ['IMAGE'],
    fields: 'media_url,media_type,caption,permalink'
  }),
  methods: {
    getUserFeed () {
      this.loading = true
      api.get('/settings').then((response) => {
        console.log(response)
        axios
          .get('https://graph.instagram.com/me/media', {
            params: {
              access_token: response.data.data.instagram_token,
              fields: this.fields
            }
          })
          .then((response) => {
            this.loading = false
            if (response.status === 400) {
              this.error = response.error.message
            }
            if (response.status === 200) {
              for (const n in response.data.data) {
                if (this.mediatypes.includes(response.data.data[n].media_type)) {
                  this.feeds.push(response.data.data[n])
                  if (this.feeds.length >= response.data.data.instagram_number_of_posts) {
                    return
                  }
                }
              }
            }
          }).catch((error) => {throw error})
      })
    }
  }
}
</script>

