<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-col mb-16 sm:text-center">
        <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {{ $t('contact.header') }}
          </h2>
        </div>
      </div>
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert" v-if="fail">
        <span class="block sm:inline">{{ $t('contact.fail') }}</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer">
          <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="close_fail">
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
          </svg>
        </span>
      </div>
      <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert" v-if="success">
        <span class="block sm:inline">{{ $t('contact.success') }}</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer">
          <svg class="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="close_success">
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
          </svg>
        </span>
      </div>
      <div class="lg:w-1/2 md:w-2/3 mx-auto">
        <div class="flex flex-wrap -m-2">
          <div class="p-2 w-1/2">
            <div class="">
              <label for="name" class="leading-7 text-sm text-gray-600">{{ $t('contact.name') }}</label>
              <input
                  type="text"
                  id="name"
                  name="name"
                  v-model.trim="$v.name.$model"
                  :class="[get_color($v.name.required, $v.name.minLength), get_focus_color($v.name.required, $v.name.minLength)]"
                  class="w-full bg-opacity-50 rounded border border-gray-300
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base
                  outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
          </div>
          <div class="p-2 w-1/2">
            <div class="">
              <label for="email" class="leading-7 text-sm text-gray-600">{{ $t('contact.email') }}</label>
              <input
                  type="email"
                  id="email"
                  name="email"
                  v-model.trim="$v.email.$model"
                  :class="[get_color($v.email.required, $v.email.minLength), get_focus_color($v.email.required, $v.email.minLength)]"
                  class="w-full bg-opacity-50 rounded border border-gray-300
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base
                  outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="">
              <label for="message" class="leading-7 text-sm text-gray-600">{{ $t('contact.message') }}</label>
              <textarea
                  id="message"
                  name="message"
                  v-model.trim="$v.message.$model"
                  :class="[get_color($v.message.required, $v.message.minLength), get_focus_color($v.message.required, $v.message.minLength)]"
                  class="w-full bg-opacity-50 rounded border border-gray-300
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base
                  outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out">
              </textarea>
            </div>
          </div>
          <div class="p-2 w-full">
            <button
                :class="send_disabled ? 'disabled:opacity-50' : ''"
                class="flex mx-auto text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg"
                @click="send_contact_email"
                :disabled="send_disabled ? 'disabled' : null"
            >
              {{ $t('contact.send') }}
            </button>
          </div>
          <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
            <p class="leading-normal my-5">
              <b>Construction Yard OÜ</b><br/>
              {{ $t('contact.register_code') }}: 12781296<br/>
              {{ $t('contact.phone') }}: + 372 55 90 70 56<br/>
              {{ $t('contact.email') }}: <a href="mailto:hello@bouquet.ee">hello@bouquet.ee</a><br/>
              {{ $t('contact.bank_account') }}: EE327700771001760429, LHV Pank<br/><br/>
              {{ $t('contact.note_1') }}<br/>
              {{ $t('contact.note_2') }}<br/>
            </p>
            <span class="inline-flex">
            <a href="https://www.facebook.com/bouquet.ee" class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/bouquet.ee" class="ml-4 text-gray-500">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                   class="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
          </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Contact',
  props: {},
  data() {
    return {
      name: '',
      email: '',
      message: '',
      success: false,
      fail: false
    }
  },
  computed: {
    send_disabled () {
      return !(this.get_status(this.$v.name.required, this.$v.name.minLength)
          && this.get_status(this.$v.email.required, this.$v.email.minLength)
          && this.get_status(this.$v.message.required, this.$v.message.minLength))
    }
  },
  methods: {
    get_status (required, min_length) {
      if (!required && min_length) {
        return false
      }
      return min_length;
    },
    get_color (required, min_length) {
      if (!required && min_length) {
        return 'bg-gray-200'
      }
      if (!min_length) {
        return 'bg-red-200'
      }
      return 'bg-green-200'
    },
    get_focus_color (required, min_length) {
      if (!required && min_length) {
        return 'focus:bg-red-100'
      }
      if (!min_length) {
        return 'focus:bg-red-100'
      }
      return 'focus:bg-green-100'
    },
    close_fail() {
      this.fail = false;
    },
    close_success() {
      this.success = false;
    },
    send_contact_email () {
      api.post('/email/contact_email', {
        name: this.name,
        email: this.email,
        message: this.message
      }).then(() => {
        this.success = true
        this.name = ''
        this.email = ''
        this.message = ''
      }).catch(() => {
        this.fail = true
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      minLength: minLength(3)
    },
    message: {
      required,
      minLength: minLength(10)
    }
  }
}
</script>

